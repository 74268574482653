/* tslint:disable */
/* eslint-disable */
/**
 * Nibyou PKI Service
 * The Nibyou PKI (Public Key Infrastructure) Service API  allows Nibyou users to share their public RSA keys with other users.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface JsonResponse
 */
export interface JsonResponse {
    /**
     * Data from the API response, can be of any type
     * @type {object}
     * @memberof JsonResponse
     */
    'data'?: object;
    /**
     * Message from the API, likely a status
     * @type {string}
     * @memberof JsonResponse
     */
    'message'?: string;
    /**
     * Error, if thrown
     * @type {object}
     * @memberof JsonResponse
     */
    'error'?: object;
    /**
     * Timestamp of the start of the response
     * @type {string}
     * @memberof JsonResponse
     */
    'ts': string;
}

/**
 * HealthApi - axios parameter creator
 * @export
 */
export const HealthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appControllerGetHealth: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HealthApi - functional programming interface
 * @export
 */
export const HealthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HealthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appControllerGetHealth(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JsonResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appControllerGetHealth(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HealthApi - factory interface
 * @export
 */
export const HealthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HealthApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appControllerGetHealth(options?: any): AxiosPromise<JsonResponse> {
            return localVarFp.appControllerGetHealth(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HealthApi - object-oriented interface
 * @export
 * @class HealthApi
 * @extends {BaseAPI}
 */
export class HealthApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthApi
     */
    public appControllerGetHealth(options?: AxiosRequestConfig) {
        return HealthApiFp(this.configuration).appControllerGetHealth(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * KeyApi - axios parameter creator
 * @export
 */
export const KeyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns a Public RSA Key for a given ID
         * @summary Get Key
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        keyControllerGetKey: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('keyControllerGetKey', 'id', id)
            const localVarPath = `/key/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a Redis ping response
         * @summary Get Ping Response
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        keyControllerPing: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/key/ping`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sets a key with a value for the current user
         * @summary Set Key
         * @param {object} body Set a key with a value for the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        keyControllerSetKey: async (body: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('keyControllerSetKey', 'body', body)
            const localVarPath = `/key`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KeyApi - functional programming interface
 * @export
 */
export const KeyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = KeyApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns a Public RSA Key for a given ID
         * @summary Get Key
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async keyControllerGetKey(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JsonResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.keyControllerGetKey(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a Redis ping response
         * @summary Get Ping Response
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async keyControllerPing(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.keyControllerPing(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sets a key with a value for the current user
         * @summary Set Key
         * @param {object} body Set a key with a value for the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async keyControllerSetKey(body: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.keyControllerSetKey(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * KeyApi - factory interface
 * @export
 */
export const KeyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = KeyApiFp(configuration)
    return {
        /**
         * Returns a Public RSA Key for a given ID
         * @summary Get Key
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        keyControllerGetKey(id: string, options?: any): AxiosPromise<JsonResponse> {
            return localVarFp.keyControllerGetKey(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a Redis ping response
         * @summary Get Ping Response
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        keyControllerPing(options?: any): AxiosPromise<void> {
            return localVarFp.keyControllerPing(options).then((request) => request(axios, basePath));
        },
        /**
         * Sets a key with a value for the current user
         * @summary Set Key
         * @param {object} body Set a key with a value for the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        keyControllerSetKey(body: object, options?: any): AxiosPromise<void> {
            return localVarFp.keyControllerSetKey(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * KeyApi - object-oriented interface
 * @export
 * @class KeyApi
 * @extends {BaseAPI}
 */
export class KeyApi extends BaseAPI {
    /**
     * Returns a Public RSA Key for a given ID
     * @summary Get Key
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KeyApi
     */
    public keyControllerGetKey(id: string, options?: AxiosRequestConfig) {
        return KeyApiFp(this.configuration).keyControllerGetKey(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a Redis ping response
     * @summary Get Ping Response
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KeyApi
     */
    public keyControllerPing(options?: AxiosRequestConfig) {
        return KeyApiFp(this.configuration).keyControllerPing(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sets a key with a value for the current user
     * @summary Set Key
     * @param {object} body Set a key with a value for the current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KeyApi
     */
    public keyControllerSetKey(body: object, options?: AxiosRequestConfig) {
        return KeyApiFp(this.configuration).keyControllerSetKey(body, options).then((request) => request(this.axios, this.basePath));
    }
}


